import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Container } from "../components/lib/Container"
import PlanList from "../components/PlanList"
import IcomoonReact from "icomoon-react"
import iconSet from "./../styles/iconmoon.json"
/* import { colorThemes } from "./../styles/colorThemes" */
import HeaderSection from "./../components/lib/HeaderSection"
import Footer from "../components/lib/Footer"
import { tokens } from "@atrilflamenco/react-components"
import { getColor } from "../utils/getColor"
import { getIcon } from "../utils/getIcon"
import { micromark } from "micromark"
import { mappedNotionData } from "../utils/notionData"

const ButtonCategory = styled.button`
  svg {
    color: ${props =>
      props.active
        ? tokens.colors[props.color + "600"]
        : tokens.colors[props.color + "500"]};
  }
`

const categoryDescriptions = [
  `Esta primera categoría de planes de lectura clarifica una serie de conceptos recurrentes en los contenidos de esta aplicación y habituales en el ámbito de la musicología, pero que no resultan familiares en los ámbitos de tradición o afición al flamenco. Asimismo, es finalidad de esta categoría aclarar los matices o diferencias existentes entre la acepción académica de ciertos términos o conceptos y la interpretación que de ellos se hace en alguno de nuestros textos. Por ello, es recomendable completar todos los planes de esta categoría, en general breves, antes de saltar a las demás categorías, pues te dará la perspectiva musicológica necesaria para que saques el máximo partido a los distintos temas flamencos que te proponemos.`,
  `Esta segunda categoría de planes de lectura reúne los aspectos musicales más relevantes del flamenco, aquellos que suelen suscitar mayor interés entre aficionados y profesionales, relacionados en este caso con el toque, el cante y las palmas. Todos los planes abordan temas de alcance transversal, es decir, que afectan al lenguaje flamenco en su conjunto más que a géneros o palos concretos, ofreciendo una aproximación global a las claves musicales del flamenco, necesaria antes de abordar los elementos singulares de cada palo. Asimismo, los variados temas desarrollados en estos planes son relevantes no sólo desde el punto de vista conceptual o teórico, sino también en la praxis flamenca, de modo que su lectura contribuirá a hacer más consciente y competente tu escucha y, si es el caso, a mejorar tu capacidad de llevar el flamenco a la práctica de forma solvente y segura.`,
  `El flamenco es una música de tradición oral, es decir, que no se transmite mediante partitura. Esta forma tácita de preservar y a la vez desarrollar el repertorio, basada en una gran sensibilidad visual y sobre todo auditiva, ha generado no obstante una cierta dificultad a la hora de describir verbalmente el flamenco. Así, han pervivido a lo largo de los años ciertas descripciones erróneas o superficiales acerca de sus características musicales, ideas preconcebidas que la flamencología está hoy en condiciones de rebatir. Esta categoría de planes de lectura ha sido pensada precisamente para rebatir algunas de estas ideas preconcebidas o lugares comunes, desvelando a través del análisis musicológico aspectos ocultos de la música flamenca y que sin duda te sorprenderán.`,
  `Esta categoría de planes de lectura te acerca a toda una serie de facetas del flamenco que, sin ser nucleares a su lenguaje musical, explican en gran parte su potencial como forma de expresión artística. Nos referimos a aspectos de orden estético, antropológico y cultural relacionados con la capacidad intrínseca del flamenco para involucrar la esfera psicológica y social del individuo, así como la variedad de tradiciones musicales que han confluido o dejado su impronta en el flamenco o la transformación de los paradigmas estéticos provocada por el progreso tecnológico. La lectura de los planes de esta categoría te aportará pues una perspectiva mucho más amplia en torno al flamenco entendido como expresión cultural antes que musical.`,
  `Esta categoría de planes de lectura te acerca uno a uno a todos los palos flamencos. Los planes vienen ordenados acorde a la clasificación métrica de los palos flamencos establecida tras un análisis en niveles de pulso aplicado sistemáticamente a todo el repertorio flamenco acompasado (en el plan A2 se explica este tipo de análisis, mientras que el plan B1 recoge la clasificación del repertorio acorde a este análisis). La clasificación contempla solamente los palos que se basan en una combinación exclusiva de fórmula métrica (compás), ostinatos armónicos (secuencias de acordes recurrentes) y movimiento (márgenes de tempo o velocidad). No obstante, la mayoría de palos contemplan varios planes, el primero dedicado a sus características musicales, el segundo a los orígenes y, en ocasiones, un tercero dedicado a los palos de nombre distinto pero cuyo acompañamiento a la guitarra deriva o es subsidiario del palo en cuestión. Asimismo, esta categoría incluye planes acerca de los palos no acompasados y los palos sin acompañamiento de guitarra. Por último, te recomendamos usar el buscador para encontrar las referencias a aquellos nombres de palo que no hemos considerado suficientemente extensas o detalladas como para generar un plan de lectura específico.`,
]

export default function Home({ data }) {
  const { planCategorias } = data.atril
  const [planCategory, setPlanCategory] = useState(null)

  const notionData = mappedNotionData(data.notion)

  return (
    <>
      <Container className="!pb-0">
        <HeaderSection
          title="Planes de lectura"
          subtitle={notionData.title}
          text={micromark(notionData.body)}
        />
      </Container>
      <div className="px-5 md:px-10 sticky top-[4rem] z-10 backdrop-blur-xl bg-white/50">
        <div className="max-w-screen-xl flex flex-row gap-2 mt-8  overflow-auto -mx-5 px-5 md:-mx-10 md:px-10 lg:mx-auto lg:px-0 no-scrollbar border-b border-[rgba(0,0,0,0.08)] py-5 ">
          {planCategorias.map(categoria => (
            <ButtonCategory
              key={categoria.id}
              className="flex-1 rounded px-3 py-2 uppercase text-xs justify-center whitespace-nowrap font-semibold flex items-center gap-3 border border-gray-100 bg-gray-50 hover:border-gray-200 focus:bg-gray-100 transition-all duration-100"
              type="button"
              onClick={() =>
                setPlanCategory(
                  planCategory === categoria.id ? null : categoria.id
                )
              }
              active={planCategory === categoria.id}
              color={getColor(categoria.id)}
            >
              <IcomoonReact
                iconSet={iconSet}
                color="currentColor"
                size={18}
                icon={getIcon(categoria.id)}
                className="w-auto"
              />
              {categoria.name}
            </ButtonCategory>
          ))}
        </div>
      </div>
      <Container className="!pt-0">
        <div className="max-w-screen-xl mx-auto">
          {planCategory && (
            <p className="mt-8 leading-relaxed">
              {categoryDescriptions[planCategory - 1]}
            </p>
          )}
          <PlanList filter={planCategory} />
        </div>
      </Container>
      <Footer />
    </>
  )
}

export const query = graphql`
  query {
    atril {
      planCategorias {
        id
        name
        color
      }
    }
    notion(title: { eq: "planes-header" }) {
      title
      properties {
        title {
          value
        }
        body {
          value
        }
      }
    }
  }
`
